import url from "url";
import IOrder from "@sellout/models/.dist/interfaces/IOrder";
import {
  OrderActionTypes,
  OrderActionCreatorTypes,
  SetOrderIdAction,
  CacheOrdersAction,
  setOrderRefundedAction,
  SetBatchPrintOrderIdsAction,
  SetTicketIdsAction,
  SetAddonsIdsAction,
  SetSharingEmailAction,
  SetErrorsAction,
  SetPhoneNumberAction,
  SetUserExistsAction,
  SetUserConfirmTicketAction,
  SetUserSharedIdsAction,
  SetUserSharedLoginAction,
  SetUserSharedLoginPhoneNumberAction,
  SetUserSharedAcceptModalAction,
  SetUserNavigationRouteAction,
  SetUserConfirmAcceptModalAction,
  setYcReAttemptOrderAction,
} from "../actions/order.actions";
import UrlParams from "../../models/interfaces/UrlParams";
import * as UrlUtil from "@sellout/utils/.dist/UrlUtil";
import * as ReduxUtil from "@sellout/utils/.dist/ReduxUtil";
import orderState from "../../models/states/order.state";

export const NEW_ORDER_ID: string = "new";

export enum ErrorKeyEnum {
  UserEmail = "UserEmail",
  UserPhoneNumber = "UserPhoneNumber",
}
export interface IOrdersCache {
  [orderId: string]: IOrder;
}
export type ErrorMap = {
  [key in ErrorKeyEnum]?: string;
};

type OrderReducerState = {
  orderId: string;
  ordersCache: IOrdersCache;
  refunded: boolean;
  reAttemptYC?: boolean;
  orderIds?: any[];
  selectedTickets: string[];
  selectedAddons: string[];
  email?: string;
  phoneNumber?: string;
  errors?: ErrorMap;
  userExists?: boolean; // true if user exists in database
  confirmTicket?: boolean; // used for ticket confirmation
  ordersId?: string;
  sharingId?: string;
  indicator?: boolean;
  sharedLoginEmail?: string;
  sharedLoginPhoneNumber?: string;
  acceptModalDetails?: any;
  naviagtionRoute: boolean;
  confirmAcceptModal?: boolean;
};

function orderReducerState(): OrderReducerState {
  const { query } = url.parse(window.location.toString(), true);
  const { orderId = "" }: UrlParams = query;

  return {
    orderId,
    ordersCache: {
      [NEW_ORDER_ID]: orderState(),
    },
    refunded: false,
    reAttemptYC: false,
    orderIds: [],
    selectedTickets: [],
    selectedAddons: [],
    email: "",
    phoneNumber: "",
    confirmTicket: true,
    ordersId: "",
    sharingId: "",
    indicator: false,
    sharedLoginEmail: "",
    sharedLoginPhoneNumber: "",
    acceptModalDetails: {},
    naviagtionRoute: false,
    confirmAcceptModal: false,
  };
}

export default function reducer(
  state = orderReducerState(),
  action: OrderActionCreatorTypes
) {
  const { type, payload } = action;

  switch (type) {
    /********************************************************************************
     *  General Order Reducers
     *******************************************************************************/
    case OrderActionTypes.SET_ORDER_ID:
      return setOrderId(state, payload as SetOrderIdAction["payload"]);

    /********************************************************************************
     *  Batch Print Order Ids Reducers
     *******************************************************************************/
    case OrderActionTypes.SET_BATCH_PRINT_ORDER_IDS:
      return setBatchPrintOrderIds(
        state,
        payload as unknown as SetBatchPrintOrderIdsAction["payload"]
      );

    /********************************************************************************
     *  Order Cache
     *******************************************************************************/

    case OrderActionTypes.CACHE_ORDERS:
      return cacheOrders(state, payload as CacheOrdersAction["payload"]);

    /********************************************************************************
     *  Order Refunded Success/Fails
     *******************************************************************************/

    case OrderActionTypes.ORDER_REFUNDED:
      return setOrderRefunded(
        state,
        payload as setOrderRefundedAction["payload"]
      );

    /********************************************************************************
     *  YC Integration Re attempt Order Success/Fails
     *******************************************************************************/

    case OrderActionTypes.SET_YC_INTEGRATION_RESPONSE:
      if ("reAttemptYC" in payload) {
        return setYcReAttemptOrder(
          state,
          payload as setYcReAttemptOrderAction["payload"]
        );
      }
      return state; // Fallback if payload is incorrect

    /********************************************************************************
     *  My Ticket Selected Ticket Ids
     *******************************************************************************/

    case OrderActionTypes.SET_TICKET_IDS:
      return setTicket(state, payload as SetTicketIdsAction["payload"]);

    /********************************************************************************
     *  My Ticket Selected Upgrade Ids
     *******************************************************************************/

    case OrderActionTypes.SET_ADD_ONS_IDS:
      return setAddons(state, payload as SetAddonsIdsAction["payload"]);

    /********************************************************************************
     *  My Ticket Sharing Email
     *******************************************************************************/

    case OrderActionTypes.SET_SHARING_EMAIL:
      return setSharingEmail(
        state,
        payload as SetSharingEmailAction["payload"]
      );

    /********************************************************************************
     *  My Ticket Errors
     *******************************************************************************/
    case OrderActionTypes.SET_ERRORS:
      return SetErrors(state, payload as SetErrorsAction["payload"]);

    /********************************************************************************
     *  My Ticket Phone-Number
     *******************************************************************************/
    case OrderActionTypes.SET_PHONE_NUMBER:
      return SetPhoneNumber(state, payload as SetPhoneNumberAction["payload"]);

    /********************************************************************************
     *  Set Phone-Number User Exist
     *******************************************************************************/
    case OrderActionTypes.SET_USER_EXISTS:
      return SetUserExists(state, payload as SetUserExistsAction["payload"]);

    /********************************************************************************
     *  Set User Confirm Ticket
     *******************************************************************************/
    case OrderActionTypes.SET_USER_CONFIRM_TICKET:
      return SetUserConfirmTicket(
        state,
        payload as SetUserConfirmTicketAction["payload"]
      );

    /********************************************************************************
     *  Set User Shared Ids
     *******************************************************************************/
    case OrderActionTypes.SET_USER_SHARED_IDS:
      return SetUserSharedIds(
        state,
        payload as SetUserSharedIdsAction["payload"]
      );

    /********************************************************************************
     *  Set User Shared Login Email
     *******************************************************************************/
    case OrderActionTypes.SET_USER_SHARED_LOGIN_EMAIL:
      return SetSharedLoginEmail(
        state,
        payload as SetUserSharedLoginAction["payload"]
      );

    /********************************************************************************
     *  Set User Shared Login Phone-Number
     *******************************************************************************/
    case OrderActionTypes.SET_USER_SHARED_LOGIN_PHONE_NUMBER:
      return SetSharedLoginPhoneNumber(
        state,
        payload as SetUserSharedLoginPhoneNumberAction["payload"]
      );

    /********************************************************************************
     *  Set User Shared Accept Modal Details
     *******************************************************************************/
    case OrderActionTypes.SET_USER_SHARED_ACCEPT_MODAL:
      return SetSharedAcceptModal(
        state,
        payload as SetUserSharedAcceptModalAction["payload"]
      );

    /********************************************************************************
     *  Set User Navigation Route
     *******************************************************************************/
    case OrderActionTypes.SET_USER_NAVIGATION_ROUTE:
      return SetNavigationRoute(
        state,
        payload as SetUserNavigationRouteAction["payload"]
      );

    /********************************************************************************
     *  Set User Confirm Accept Modal Tickets
     *******************************************************************************/
    case OrderActionTypes.SET_USER_CONFIRM_ACCEPT_MODAL:
      return SetConfirmAcceptModal(
        state,
        payload as SetUserConfirmAcceptModalAction["payload"]
      );

    default:
      return state;
  }
}

/********************************************************************************
 *  Set Order ID
 *******************************************************************************/

function setOrderId(
  state: OrderReducerState,
  { orderId }: { orderId: string }
): OrderReducerState {
  UrlUtil.setQueryString({ orderId });

  return {
    ...state,
    orderId,
  };
}

/********************************************************************************
 *  Set Ticket-IDS
 *******************************************************************************/

function setTicket(
  state: OrderReducerState,
  { selectedTickets }: { selectedTickets: any }
): OrderReducerState {
  return {
    ...state,
    selectedTickets,
  };
}
/********************************************************************************
 *  Set Add-ons-IDS
 *******************************************************************************/

function setAddons(
  state: OrderReducerState,
  { selectedAddons }: { selectedAddons: any }
): OrderReducerState {
  return {
    ...state,
    selectedAddons,
  };
}
/********************************************************************************
 *  Set Sharing Email
 *******************************************************************************/

function setSharingEmail(
  state: OrderReducerState,
  { email }: { email: any }
): OrderReducerState {
  return {
    ...state,
    email,
  };
}
/********************************************************************************
 *   Set Errors Sharing Ticket
 *******************************************************************************/

function SetErrors(
  state: OrderReducerState,
  { key, errors }: { key: ErrorKeyEnum; errors: any }
): OrderReducerState {
  return {
    ...state,
    errors: {
      ...state.errors,
      [key]: errors,
    },
  };
}
/********************************************************************************
 *   Set Phone-Number Sharing Ticket
 *******************************************************************************/

function SetPhoneNumber(
  state: OrderReducerState,
  { phoneNumber }: { phoneNumber: any }
): OrderReducerState {
  return {
    ...state,
    phoneNumber,
  };
}
/********************************************************************************
 *   Set Phone-Number User Exist
 *******************************************************************************/

function SetUserExists(
  state: OrderReducerState,
  { userExists }: { userExists: any }
): OrderReducerState {
  return {
    ...state,
    userExists,
  };
}
/********************************************************************************
 *   Set User Confirm Ticket
 *******************************************************************************/

function SetUserConfirmTicket(
  state: OrderReducerState,
  { confirmTicket }: { confirmTicket: boolean }
): OrderReducerState {
  return {
    ...state,
    confirmTicket,
  };
}
/********************************************************************************
 *   Set User Shared Ids
 *******************************************************************************/

function SetUserSharedIds(
  state: OrderReducerState,
  {
    ordersId,
    sharingId,
    indicator,
  }: { ordersId: string; sharingId: string; indicator: boolean }
): OrderReducerState {
  return {
    ...state,
    ordersId,
    sharingId,
    indicator,
  };
}
/********************************************************************************
 *   Set User Shared Login Email
 *******************************************************************************/

function SetSharedLoginEmail(
  state: OrderReducerState,
  { sharedLoginEmail }: { sharedLoginEmail: string }
): OrderReducerState {
  return {
    ...state,
    sharedLoginEmail,
  };
}
/********************************************************************************
 *  Set User Shared Login Phone-Number
 *******************************************************************************/

function SetSharedLoginPhoneNumber(
  state: OrderReducerState,
  { sharedLoginPhoneNumber }: { sharedLoginPhoneNumber: string }
): OrderReducerState {
  return {
    ...state,
    sharedLoginPhoneNumber,
  };
}
/********************************************************************************
 *   Set User Shared Accept Modal Details
 *******************************************************************************/

function SetSharedAcceptModal(
  state: OrderReducerState,
  { acceptModalDetails }: { acceptModalDetails: string }
): OrderReducerState {
  return {
    ...state,
    acceptModalDetails,
  };
}
/********************************************************************************
 *   Set User Navigation Route
 *******************************************************************************/

function SetNavigationRoute(
  state: OrderReducerState,
  { naviagtionRoute }: { naviagtionRoute: boolean }
): OrderReducerState {
  return {
    ...state,
    naviagtionRoute,
  };
}
/********************************************************************************
 *   Set User Confirm Accept Modal Tickets
 *******************************************************************************/

function SetConfirmAcceptModal(
  state: OrderReducerState,
  { confirmAcceptModal }: { confirmAcceptModal: boolean }
): OrderReducerState {
  return {
    ...state,
    confirmAcceptModal,
  };
}
/********************************************************************************
 *  Set Batch Print Order ID'S
 *******************************************************************************/

function setBatchPrintOrderIds(
  state: OrderReducerState,
  { orderIds }: { orderIds: any }
): OrderReducerState {
  UrlUtil.setQueryString({ orderIds });

  return {
    ...state,
    orderIds,
  };
}

/********************************************************************************
 *  Cache Orders
 *******************************************************************************/

function cacheOrders(
  state: OrderReducerState,
  { orders }: { orders: IOrder[] }
): OrderReducerState {
  return {
    ...state,
    ordersCache: ReduxUtil.makeCache(orders, "_id", state.ordersCache),
  };
}

/********************************************************************************
 *  Cache Orders
 *******************************************************************************/

function setOrderRefunded(
  state: OrderReducerState,
  { refunded }: { refunded: boolean }
): OrderReducerState {
  return {
    ...state,
    refunded,
  };
}
/********************************************************************************
 *  YC Integration Re attempt Order Success/Fails
 *******************************************************************************/

function setYcReAttemptOrder(
  state: OrderReducerState,
  { reAttemptYC }: { reAttemptYC: boolean }
): OrderReducerState {
  return {
    ...state,
    reAttemptYC,
  };
}
