import React, { useEffect, useState } from "react";
import { Button, Colors, Flex, Icon, Icons, Loader, LoaderSizes } from "@sellout/ui";
import { ButtonTypes, ButtonStates } from "@sellout/ui/build/components/Button";
import GET_ORDER from "@sellout/models/.dist/graphql/queries/order.query";
import ORDER_INTEGRATION_UPDATE from "@sellout/models/.dist/graphql/mutations/orderIntegrationUpdate.mutation";
import { ButtonContainer } from "../../pages/EventOrders.page";
import { Container } from "./AddTicketBlockModal";
import * as AppActions from "../../redux/actions/app.actions";
import * as Time from "@sellout/utils/.dist/time";
import * as OrderActions from "../../redux/actions/order.actions";
import Error from "../../elements/Error";
import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { BackstageState } from "../../redux/store";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Section } from "./UserOrderTicketModal";
import { Accordion, AccordionBody, AccordionHeading } from "../AccordionMenu";

const ItemTextLight = styled.div`
  font-size: 1.3rem;
  color: ${Colors.Grey2};
  font-weight: 500;
  margin-right: 10px;
`;
const ItemTextBold = styled.div`
  font-size: 1.2rem;
  color: ${Colors.Grey1};
  font-weight: 600;
  text-align: justify;
`;

enum ModalProps {
  title = "Order Integration History",
  cancelText = "CLOSE",
  confirmText = "RE-SEND",
}
const YcOrderDetailsModal = () => {
  /* Actions */
  const dispatch = useDispatch();
  const orderState = useSelector((state: BackstageState) => state.order);
  const [show, setShow] = useState<number[]>([]);

  const { orderId } = orderState;
  const popModal = () => dispatch(AppActions.popModal());

    const [reAttemptOrderInteg, { loading:loadingOrder, error }] = useMutation(ORDER_INTEGRATION_UPDATE, {

      onCompleted(data) {
        dispatch(OrderActions.setYcReAttemptOrder(true));
        popModal()
      },
    });
  const [getOrder, { data: ycOrderDetails, loading:historyLoading }] = useLazyQuery(
    GET_ORDER,
    {
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (orderId) {
      getOrder({
        variables: {
          orderId,
        },
      });
    }
  }, [orderId]);

  const openAccordion = (index: number) => {
    setShow((prevShow) =>
      prevShow.includes(index)
        ? prevShow.filter((i) => i !== index) 
        : [...prevShow, index]                
    );
  };
  const sortedResponses = [...(ycOrderDetails?.order?.orderIntegrationResponse || [])].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  
  const latestResponse = sortedResponses[0];
  
  return (
    <>
      <ModalContainer width="430px">
        <>
          <ModalHeader title={ModalProps.title} close={popModal} />
          <ModalContent>
            <Container>
              {historyLoading ?(
                <Flex justify="center">
                <Loader size={LoaderSizes.Medium} color={Colors.Orange} />
                </Flex>
              ):(
              <Flex justify="space-between" direction="column">

                {ycOrderDetails?.order?.orderIntegrationResponse.map(
                  (ycOrderApiInfo,index) => {
                    const isTimeZone = ycOrderDetails?.order.event
                    ? ycOrderDetails?.order?.event?.venue?.address?.timezone
                    : ycOrderDetails?.order?.season?.venue?.address?.timezone;
          
                  const timezone = isTimeZone
                    ? ycOrderDetails?.order?.event
                      ? ycOrderDetails?.order?.event?.venue?.address?.timezone
                      : ycOrderDetails?.order?.season?.venue?.address?.timezone
                    : undefined;
                    return (
                      <>
                      <Accordion key={index}>
                        <AccordionHeading
                          active={show.includes(index)}
                          onClick={() => openAccordion(index)}
                        >
                          {" "}
                          {Time.format(
                                new Date(ycOrderApiInfo.createdAt), 
                                "ddd, MMM DD, YYYY [at] h:mma",
                                timezone
                              )}
                          <Icon
                            icon={show.includes(ycOrderApiInfo.index)
                              ? Icons.AngleDownRegular
                              : Icons.AngleDownRegular}
                            color={show.includes(ycOrderApiInfo.index) ? Colors.White : Colors.Grey1}
                            size={18}
                            position="absolute"
                            right="0"
                            top="10px"
                            margin="0px 10px 0px 0px" />
                        </AccordionHeading>
                        <AccordionBody className={show.includes(index) ? "show" : ""}>
                          <Section>
                           {Object.entries(ycOrderApiInfo).filter(
                            ([key]) => !["__typename", "title", "statusCode", "_id"].includes(key)
                           )
                            .map(([key, value]) => {
                              return (
                                <Flex justify="space-between" key={key}>
                                  <ItemTextLight>{key}</ItemTextLight>
                                  <ItemTextBold>
                                    {key === "createdAt"
                                      ? Time.format(
                                        new Date(ycOrderApiInfo.createdAt), 
                                        "ddd, MMM DD, YYYY [at] h:mma",
                                        timezone
                                      )
                                      : String(value)}
                                  </ItemTextBold>
                                </Flex>
                              );
                            })}
                        </Section>
                   </AccordionBody>
                    </Accordion>
                        </>
                    )
                      }
                   )}
                    <Error
                      children={error as unknown as string }
                      margin="10px 0px 0px 0px"
                        />
              </Flex>
                  )}
            </Container>
          </ModalContent>
          <ModalFooter>
            <div />
            <ButtonContainer>
              { latestResponse && !latestResponse.success ? 
              <Button
                type={ButtonTypes.Thin}
                text={ModalProps.confirmText}
                state={ButtonStates.Active}
                loading={loadingOrder}
                bgColor={Colors.Green}
                margin="0px 10px 0px 0px"
                onClick={() => {
                  reAttemptOrderInteg({
                    variables:{
                    orderId,
                    }
                    });
                    }}
              /> :""
              }
              <Button
                type={ButtonTypes.Thin}
                text={ModalProps.cancelText}
                state={ButtonStates.Warning}
                margin="0px 10px 0px 0px"
                onClick={() => popModal()}
              />
            </ButtonContainer>
          </ModalFooter>
        </>
      </ModalContainer>
    </>
  );
};

export default YcOrderDetailsModal;
